export enum UserStatus {
    ACTIVE = 'U_ACTIVE',
    REQ_PASSWORD = 'U_REQ_PASSWORD',
    VERIF_OTP = 'U_VERIF_OTP',
    BLOCK = 'U_BLOCK',
    REQUEST_CHANGE_PASSWORD = 'U_REQ_CHANGE_PASSWORD',
}

export enum UserRole {
    USER = 'U_USER',
    RECRUITER = 'U_RECRUITER',
    STAFF_RECEIPT = 'U_STAFF_RECEIPT',
    STAFF_TECH = 'U_STAFF_TECH',
    STAFF = 'U_STAFF',
    SALE = 'U_SALE',
    SALE_ADMIN = 'U_SALE_ADMIN',
    ADMIN = 'U_ADMIN',
    STAFF_ADMIN = 'U_STAFF_ADMIN',
    SUPER_ADMIN = 'U_SUPER_ADMIN',
}
export enum UserGender {
    MALE = 'U_MALE',
    FEMALE = 'U_FEMALE',
    OTHER = 'U_OTHER',
}

export interface User {
    uuid: string
    full_name: string
    first_name: string
    last_name: string
    birthday: string
    phone: string
    role: UserRole
    gender: UserGender
    avatar: string
    background: string
    country_name: string
    country_code: string
    city_name: string
    district_name: string
    ward_name: string
    language: string
    status: UserStatus
    email: string
    password: string
    recaptchaValue?: any
}

export const optionsUserRole = [
    {value: UserRole.USER, label: 'User'},
    {value: UserRole.RECRUITER, label: 'Recruiter'},
    {value: UserRole.STAFF, label: 'Staff'},
    {
        value: UserRole.STAFF_RECEIPT,
        label: 'Staff Receipt',
    },
    {
        value: UserRole.STAFF_TECH,
        label: 'Staff Tech',
    },
    {value: UserRole.SALE, label: 'Sale'},
    {value: UserRole.SALE_ADMIN, label: 'Sale Admin'},
    {value: UserRole.ADMIN, label: 'Admin'},
    {value: UserRole.SUPER_ADMIN, label: 'Super Admin'},
    {
        value: UserRole.STAFF_ADMIN,
        label: 'Staff Admin',
    },
]
export const optionsUserStatus = [
    {
        label: 'Active',
        value: UserStatus.ACTIVE,
    },
    {
        label: 'Block',
        value: UserStatus.BLOCK,
    },
    {
        label: 'User Request Password',
        value: UserStatus.REQ_PASSWORD,
    },
    {
        label: 'User Verification OTP',
        value: UserStatus.VERIF_OTP,
    },
    {
        label: 'Request Change Password',
        value: UserStatus.REQUEST_CHANGE_PASSWORD,
    },
]

export const optionsUserGender = [
    {
        label: 'Male',
        value: UserGender.MALE,
    },
    {
        label: 'Female',
        value: UserGender.FEMALE,
    },
    {
        label: 'Other',
        value: UserGender.OTHER,
    },
]
